<template>
  <!-- 四要素认证 -->
  <div class="fill-in-wrap">
    <div class="tips" v-if="tips">
      <span><van-icon name="info-o" /></span>
      {{ tips }}
    </div>
    <div class="content">
      <div class="input-wrap" v-for="(f, i) in fields" :key="i">
        <van-field
          :type="f.type"
          v-model.trim="form[f.key]"
          :label="f.title"
          :placeholder="`请输入`"
          :rules="required"
          required
          :disabled="f.disabled"
          input-align="right"
        />
      </div>
      <div class="input-wrap verify-code">
        <van-field
          label="验证码"
          v-model="form.smsCode"
          placeholder="请输入验证码"
          required
          :rules="required"
        />
        <div :class="['send-btn', sending ? 'sending' : '']" @click="getCode">
          {{ sending ? `${second}s` : "获取验证码" }}
        </div>
      </div>
    </div>
    <div class="btns">
      <!-- <van-button size="small" @click="$router.back()">取消</van-button> -->
      <van-button type="info" size="small" @click="saveFn">提交认证</van-button>
    </div>
  </div>
</template>
<script>
import { authSmsCode, fourElements } from "@/api";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  watch: {},
  data() {
    return {
      // 输入字段类型
      enterFields: ["text", "textarea", "number", "amount"],
      required: [
        {
          required: true
        }
      ],
      tips: "",
      form: {},
      sending: false,
      second: 60,
      itv: null,
      cacheData: {}
    };
  },
  computed: {
    /*
     * accNo 银行卡号
      bizNo 订单号
      idCard 身份证号
      mobile 手机号
      name 姓名
      smsCode  验证码
     */
    fields() {
      return [
        {
          title: "姓名",
          key: "name",
          type: "text",
          disabled: true
        },
        {
          title: "身份证号",
          key: "idCard",
          type: "text",
          disabled: true,
          reg: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
        },
        {
          title: "银行卡",
          key: "accNo",
          type: "text",
          reg: /^[1-9]\d{9,29}$/
        },
        {
          title: "银行预留手机号",
          key: "mobile",
          type: "text",
          reg: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        }
      ];
    }
  },
  created() {
    const data = localStorage.getItem("_app_cache_data_") || "{}";
    this.cacheData = JSON.parse(data);
    // 后端的抽象艺术
    /**
     * 登录页 /login 返回 customerName
     * 填写信息页 /home 需要的是 customer_name
     * 验证提交页 /identity 需要提交 name
     */
    if (this.cacheData) {
      const { customerName, certNo, cert_no, customer_name, bizNo } =
        this.cacheData;
      this.$set(this.form, "name", customerName || customer_name || "");
      this.$set(this.form, "idCard", certNo || cert_no || "");
      this.$set(this.form, "bizNo", bizNo || "");
    }
  },
  mounted() {},
  methods: {
    // 校验
    checkedFields() {
      for (let i = 0; i < this.fields.length; i++) {
        const f = this.fields[i];
        const k = f.key;
        const name = f.title;
        const reg = f.reg;
        if (!this.form[k]) throw `${name}不能为空`;
        if (reg && !reg.test(this.form[k])) throw `请输入正确的${name}`;
      }
    },
    async getCode() {
      if (this.sending) return;
      const mobilePass = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.form.mobile);
      if (!mobilePass) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      try {
        await authSmsCode(this.form.mobile);
        this.$toast.success("验证码已发送，请注意查收！");
        this.sending = true;
        this.itv = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.sending = false;
            clearInterval(this.itv);
          }
        }, 1000);
        return Promise.resolve(true);
      } catch (error) {
        this.sending = false;
        this.$errMsg(error);
        return Promise.reject(error);
      }
    },
    async saveFn() {
      try {
        if (!this.form.smsCode) {
          throw "请输入验证码";
        }
        await this.checkedFields();
        const { data } = await fourElements(this.form);
        if (data == 1) {
          this.cacheData.hasIdentification = "Y";
          localStorage.setItem(
            "_app_cache_data_",
            JSON.stringify(this.cacheData)
          );
          // this.$router.push("/home");
          // this.$router.replace(`/identity-portrait?bizNo=${this.form.bizNo}`);

          const bizNo = this.$route.query.bizNo;
          this.$eventbus.$emit("verifyFollowTarget", {
            bizNo: bizNo || this.cacheData.bizNo
          });
        } else {
          this.tips = `身份认证不通过，请检查后重试；`;
        }
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .tips {
    padding: 12px 24px;
    line-height: 40px;
    font-size: 24px;
    color: #f5a227;
    background: #fff1dc;
    // border: 1px solid #f5a227;
    min-height: 48px;
    box-shadow: 0 5px 5px #72634c15;
    margin-bottom: 5px;
  }
  .content {
    flex: 1;
    overflow-y: auto;
    .input-wrap {
      width: 100%;
      height: 96px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 0 32px;
      :deep(.van-field__label) {
        width: 7em;
      }
      &.verify-code {
        display: flex;
        justify-content: space-between;
        .send-btn {
          box-sizing: border-box;

          height: 28px;
          line-height: 28px;
          border-left: 2px solid #1890ff;
          font-size: 28px;
          font-weight: 400;
          color: #1890ff;
          text-align: center;
          padding-left: 6px;

          white-space: nowrap;

          padding: 0px 32px;
          &.sending {
            color: #999;
          }
        }
      }
    }
  }
  .btns {
    border-top: 1px solid #efefef;
    height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -5px 10px #efefef;
    .van-button {
      width: 45%;
      height: 75%;
    }
  }
}
</style>
